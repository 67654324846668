const HEADER_COMPONENT = 'js-header-components';
const HEADER_COMPONENTS = 'l-header__components';
const PREHEADER_COMPONENT = 'preheader';
const STICKY_HEADER_COMPONENT = 'component-WebsiteHeaderComponent--sticky';
const STICKY_SCROLLUP_CLASS = 'component-WebsiteHeaderComponent--sticky-scrollup';
export class StickyHeader {
  constructor() {
    this.headerComponent = document.querySelector(`.${HEADER_COMPONENT}`);
    this.headerComponents = document.querySelector(`.${HEADER_COMPONENTS}`);
    this.preheaderComponent = document.querySelector(`.${PREHEADER_COMPONENT}`);
    this.observer = null;
    this.lastScrollY = window.scrollY;
    this.handleIntersectionCallback = entries => {
      this.handleIntersection(entries, this.unfixHeader.bind(this), this.fixHeader.bind(this));
    };
  }
  init() {
    this.initObserver();
    this.trackScrollDirection();
  }
  initObserver() {
    if (this.headerComponent) {
      this.observer = new IntersectionObserver(this.handleIntersectionCallback, {
        root: null,
        rootMargin: `${(this.preheaderComponent ? this.preheaderComponent.offsetHeight : 0) + (this.headerComponents ? this.headerComponents.offsetHeight : 0)}px`,
        threshold: 1
      });
      this.observer.observe(this.headerComponent);
    }
  }
  handleIntersection(entries, isIntersectingMethod, isNotIntersectingMethod) {
    entries.forEach(entry => {
      document.body.style.setProperty('--headerHeight', `${entry.target.offsetHeight}px`);
      if (entry.isIntersecting) {
        isIntersectingMethod();
      } else {
        isNotIntersectingMethod();
      }
    });
  }
  fixHeader() {
    if (this.headerComponent) {
      this.headerComponent.classList.add(`${STICKY_HEADER_COMPONENT}`);
    } else {
      throw new Error('hide header error: Header querySelector is undefined');
    }
  }
  unfixHeader() {
    if (this.headerComponent) {
      this.headerComponent.classList.remove(`${STICKY_HEADER_COMPONENT}`);
      this.headerComponent.classList.remove(STICKY_SCROLLUP_CLASS);
    } else {
      throw new Error('hide header error: Header querySelector is undefined');
    }
  }
  trackScrollDirection() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  handleScroll() {
    const currentScrollY = window.scrollY;
    if (currentScrollY < this.lastScrollY) {
      this.addScrollUpClass();
    } else {
      this.removeScrollUpClass();
    }
    this.lastScrollY = currentScrollY;
  }
  addScrollUpClass() {
    if (this.headerComponent) {
      this.headerComponent.classList.add(STICKY_SCROLLUP_CLASS);
    }
  }
  removeScrollUpClass() {
    if (this.headerComponent) {
      this.headerComponent.classList.remove(STICKY_SCROLLUP_CLASS);
    }
  }
}